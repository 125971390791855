/* eslint-disable jsx-a11y/no-autofocus */

import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import SEO from "../components/SEO";
import "../components/FrontDoor.css";
import FretlinkLogo from "../components/svg-assets/FretlinkLogo";
import { ArrowLeft24, RightArrow, Show24, Hide24, Loading30 } from "../components/svg-assets/Icons";
import frontDoorWhiteMetalSheet from "../images/front-door/front-door-white-sheet-metal.jpg";
import app from "../lib/app";
import { Helmet } from "react-helmet";

const VALID_EMAIL_PATTERN = /.+@.+\..+/;

const validateEmail = (t, email, password) => {
  const trimmedEmail = email?.trim() ?? "";

  if (!trimmedEmail.length) {
    if (password.length) {
      return t("login.errors.missing-login");
    } else {
      // If there is neither the user or the password entered, don‘t annoy the user.
      return null;
    }
  } else if (!VALID_EMAIL_PATTERN.test(trimmedEmail)) {
    return t("login.errors.invalid-email");
  }
};

const validatePassword = (t, password) => {
  if (!password?.length) {
    return t("login.errors.missing-password");
  }
  return null;
};

function redirectToApp() {
  window.location = process.env.APP_BASE_URL;
}

const LoginPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const fieldClassNames = erroneous =>
    Boolean(erroneous) ? "fd-field fd-field--erroneous" : "fd-field";

  useEffect(() => {
    app()
      .isAuthenticated()
      .then(isConnected => {
        if (isConnected) {
          setLoading(true);
          redirectToApp();
        }
      });
  }, []);

  const onSubmit = event => {
    event.preventDefault();

    const emailError = validateEmail(t, email, password);
    setEmailError(emailError);
    const passwordError = validatePassword(t, password);
    setPasswordError(passwordError);

    if (emailError || passwordError) {
      return;
    } else {
      setLoading(true);
      app()
        .login({
          email: email?.trim(),
          password,
        })
        .then(() => {
          setLoading(false);
          redirectToApp();
        })
        .catch(error => {
          setLoading(false);

          if (error?.response?.status === 401) {
            setPasswordError(t("login.errors.bad-credentials"));
            return;
          }
          if (error?.response?.status === 429) {
            setEmailError(t("login.errors.too-many-tries"));
            return;
          }

          setEmailError(t("login.errors.unknown-error"));
        });
    }
  };

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "fl-dark-blue-background",
        }}
      />
      <SEO title={t("login.html-title")} />
      <div className="fd-front-door">
        <div className="fd-illustration-flap">
          <img src={frontDoorWhiteMetalSheet} alt="" loading="lazy" />
        </div>
        <div className="fd-flap fd-flap--centered">
          <Link to="/" className="fd-back-to-website">
            <ArrowLeft24 />
            {t("login.go-back-to-website")}
          </Link>
          <form action="#login" method="POST" onSubmit={onSubmit}>
            <FretlinkLogo />

            <label className={fieldClassNames(emailError)}>
              <span className="fd-label">{t("login.form.login.label")}</span>
              <input
                type="email"
                id="email"
                value={email}
                onChange={event => {
                  setEmailError(null);
                  setEmail(event.target.value);
                }}
                onBlur={() => setEmailError(validateEmail(t, email, password))}
                autoFocus
                placeholder={t("login.form.login.placeholder")}
              />
              {emailError ? <p className="fd-error">{emailError}</p> : undefined}
            </label>

            <label className={fieldClassNames(passwordError)}>
              <span className="fd-label">{t("login.form.password.label")}</span>
              <input
                type={passwordVisible ? "text" : "password"}
                value={password}
                placeholder={t("login.form.password.placeholder")}
                id="password"
                onChange={event => {
                  setPasswordError(null);
                  setPassword(event.target.value);
                }}
                onBlur={() => setPasswordError(validatePassword(t, password))}
              />
              <button
                type="button"
                className="fd-show-password"
                title={
                  passwordVisible
                    ? t("login.form.password.hide-password")
                    : t("login.form.password.show-password")
                }
                onClick={event => {
                  setPasswordVisible(!passwordVisible);
                  event.preventDefault();
                }}>
                {passwordVisible ? <Hide24 /> : <Show24 />}
              </button>
              {passwordError ? <p className="fd-error">{passwordError}</p> : undefined}
            </label>

            <p className="fd-field-addon">
              <Link to="/lost-password/">{t("login.form.password.forgot")}</Link>
            </p>

            <button
              type="submit"
              className={`fd-next ${loading ? "fd-next--loading" : ""}`}
              title={t("login.form.submit.title")}>
              {loading ? <Loading30 /> : <RightArrow />}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
